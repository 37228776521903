// @ts-nocheck
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginSuccess, setUserPermissions } from '../../toolkit/userSilcer';
import ApiEndpoints from '../../const/ApiEndpoints';
import { loginRequest } from '../../authConfig';
import { SwalToast } from '../../components/Common';

/**
 * @function useAuth
 * @description Custom hook for handling authentication-related functionalities.
 * @returns {Object} An object containing authentication methods and user data.
 */

function useAuth() {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { instance } = useMsal();

  /**
   * @function adLogin
   * @description Initiates authentication with the Active Directory (AD) login popup.
   * @returns {Promise} A promise that resolves with the response from the API call.
   */
  const adLogin = () => {
    return instance.loginPopup(loginRequest).then((adResp) => {
      return axios
        .get(
          ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.AUTH.GET_USER_DETAILS,
          {
            headers: {
              Authorization: `JWT ${adResp.idToken}`,
            },
          }
        )
        .then(
          (res) => {
            dispatch(
              loginSuccess({
                token: adResp.idToken,
                refresh: '',
                user: res.data.user,
                profile: res.data.profile,
                remember: false,
              })
            );
            return res;
          },
          (e) => {
            // why handling error condition when we have generic logic in axios js
            // ans: backend team cant make it generic response
            if (e?.response?.data?.detail) {
              SwalToast({
                icon: 'error',
                title: e?.response?.data?.detail,
              });
            } else if (e.response?.status === 401) {
              SwalToast({
                icon: 'error',
                title: 'User not found.',
              });
            }

            throw e;
          }
        );
    });
  };

  /**
   * @funtion login
   * Initiates a login request by sending a POST request to the authentication endpoint.
   * @param {object} body - The data to be sent in the request body.
   * @param {string} body.username - The username of the user.
   * @param {string} body.password - The password of the user.
   * @param {boolean} [body.remember_me=false] - Optional. Whether to remember the user's login session (default is false).
   * @returns {Promise} A promise that resolves with the response from the API call.
   * If the login is successful, the response contains user authentication tokens and profile information.
   * If the login requires a temporary password, the response includes a flag indicating this.
   * @throws {Error} If an error occurs during the login process.
   */
  const login = (body) =>
    axios
      .post(
        ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.AUTH.LOGIN_USER,
        body
      )
      .then(
        (res) => {
          if (res?.data?.temporary_password) {
            return res;
          }
          dispatch(
            loginSuccess({
              token: res.data.tokens.access_token,
              refresh: res.data.tokens.refresh_token,
              user: res.data.user,
              profile: res.data.profile,
            })
          );
          if (window.apm) {
            // https://www.elastic.co/guide/en/apm/agent/rum-js/current/agent-api.html#apm-set-user-context
            window.apm.setUserContext({
              username: res?.data?.user?.username,
              id: res?.data?.user?.id,
              email: res?.data?.user?.email,
            });
          }
          return res;
        },
        (error) => {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('user_data');
          throw error;
        }
      );

  /**
   * @function forgetPassword
   * @description Initiates the process to reset the user's password by sending a POST request to the forget password endpoint.
   * @param {object} body - The data to be sent in the request body.
   * @param {string} body.email - The email address of the user for whom the password is to be reset.
   * @returns {Promise} A promise that resolves with the response from the API call.
   * @throws {Error} If an error occurs during the process of resetting the password.
   */
  const forgetPassword = (body) =>
    axios
      .post(
        ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.AUTH.FORGET_PASSWORD,
        body
      )
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  /**
   * @function resetPassword
   * @description Initiates the process to reset the user's password by sending a PATCH request to the reset password endpoint.
   * @param {object} body - The data to be sent in the request body.
   * @param {string} body.email - The email address of the user for whom the password is to be reset.
   * @param {string} body.newPassword - The new password for the user.
   * @param {string} body.resetToken - The token required for resetting the password.
   * @returns {Promise} A promise that resolves with the response from the API call.
   * @throws {Error} If an error occurs during the process of resetting the password.
   */
  const resetPassword = (body) =>
    axios
      .patch(
        ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.AUTH.RESET_PASSWORD,
        body
      )
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchUserPermissions
   * @description Fetches the permissions associated with the current user.
   * @returns {Promise} A promise that resolves with the response from the API call.
   * @throws {Error} If an error occurs while fetching user permissions.
   */
  const fetchUserPermissions = () =>
    axios
      .get(
        ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.AUTH.USER_PERMISSION,
        { loader: false }
      )
      .then(
        (resp) => {
          if (Object.keys(resp?.data?.user_permission).length <= 0) {
            SwalToast({
              icon: 'info',
              title:
                'This user account is not associated with any group. Please contact admin.',
            });
          }
          dispatch(
            setUserPermissions({
              permissions: resp?.data?.user_permission,
            })
          );
        },
        (error) => {
          dispatch(
            setUserPermissions({
              permissions: {},
            })
          );
          throw error;
        }
      );
  return {
    user,
    login,
    adLogin,
    forgetPassword,
    resetPassword,
    fetchUserPermissions,
  };
}

export default useAuth;

/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
// @ts-nocheck
import { cloneDeep } from 'lodash';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { API_MODULE_PATH_CONST } from '../const/API_MODULE_PATH_CONST';
import MODULE_SUB_MODULE_OPERATIONS_TREE from '../const/ModuleSubModuleOperationsTree';
import ApiEndpoints from '../const/ApiEndpoints';
import {
  TimeSeriesModelTechinquesMI,
  GlmGeeModelTechinquesMI,
  VECMModelTechinquesMI,
} from '../const/ModellingTechniques';

const { MODULE_BASE_URL } = ApiEndpoints;
const FEATURE_IMPORTANCE = 'Feature Importance';
const STRENGTH_TEST = 'strength test';
const STABILITY_TEST = 'stability test';
const utils = {
  hostNeedsToken: (token, reqUrl) => {
    return (
      token &&
      ApiEndpoints.HOSTS_NEEDS_TOKEN.includes(
        new URL(reqUrl).host.split(':')[0]
      )
    );
  },
  hostNeedsOrg: (reqUrl) => {
    return ApiEndpoints.HOSTS_NEEDS_TOKEN.includes(
      new URL(reqUrl).host.split(':')[0]
    );
  },
  extractModule: (path) => {
    const res = path.split('/');
    return {
      module: res[1],
      subModule: res[2],
    };
  },
  getCellTemaplate: (
    _cell,
    pkCell,
    colName,
    _columnToExcludeFromNumberFormatting
  ) => {
    if (_cell && colName !== 'pk') {
      return { Cell: _cell };
    }
    if (pkCell && colName === 'pk') {
      return { Cell: pkCell };
    }
    if (_columnToExcludeFromNumberFormatting?.includes(colName)) {
      return { Cell: ({ value }) => value };
    }
    return {};
  },
  tableDataColumnCalculator: (
    tableData,
    width,
    Cell,
    HeaderCell,
    columnOptionsCallbacks,
    PkCell,
    datasetList,
    activeDataset,
    fetchColumnMetaData,
    projectId,
    columnsToExcludeFromNumberFormatting = [],
    doNotRenamePk = false
  ) => {
    const dynamicTableHeaders = Object.keys(tableData[0]).map(
      (item, index) =>
        [
          {
            Header:
              item === 'pk' && !doNotRenamePk ? 'Row Id' : HeaderCell || item,
            id: item,
            accessor: (value) => {
              return value[item];
            },
            ...utils.getCellTemaplate(
              Cell,
              PkCell,
              item,
              columnsToExcludeFromNumberFormatting
            ),
            ...(width ? { width } : {}),

            ...(item === 'pk'
              ? { disableSortBy: true, canGroupBy: false }
              : {}),

            ...{ optionsCallbacks: columnOptionsCallbacks },
            heading: item,
            datasetList,
            activeDataset,
            index,
            fetchColumnMetaData,
            projectId,
          },
        ][0]
    );
    return dynamicTableHeaders;
  },

  getTableRowColumnCountCellWidth: (rows) => {
    let width = 50;
    try {
      if (
        rows.length &&
        typeof rows[0] === 'object' &&
        Object.keys(rows[0]).length
      ) {
        width =
          Math.max(rows.length, Object.keys(rows[0]).length).toString().length *
          20;
      }
      return width;
    } catch (e) {
      return width;
    }
  },
  hypertuningData: (body, taskDetails, hypertunegraphData) => {
    let graphChangehData;
    if (hypertunegraphData) {
      graphChangehData = hypertunegraphData;
    } else {
      graphChangehData = taskDetails?.results?.graph_table;
    }
    let summaryStats =
      body.results.graph_table['summary stats']['Summary Stats'];
    let summaryStatsLogistics =
      body.results.graph_table['summary stats logistic'];
    let featureImp =
      body.results.graph_table[FEATURE_IMPORTANCE][FEATURE_IMPORTANCE];
    const strengthTest = Object.keys(body.results.graph_table[STRENGTH_TEST]);
    const stabilityTest = Object.keys(body.results.graph_table[STABILITY_TEST]);
    summaryStats = {
      ...graphChangehData['summary stats']['Summary Stats'],
      ...summaryStats,
    };
    summaryStatsLogistics = {
      ...graphChangehData['summary stats logistic'],
      ...summaryStatsLogistics,
    };
    featureImp = {
      ...graphChangehData[FEATURE_IMPORTANCE][FEATURE_IMPORTANCE],
      ...featureImp,
    };
    const tableData = {
      model_id: body.results.model_id,
      res: body.results.response,
    };
    let tempStrength;
    tempStrength = graphChangehData[STRENGTH_TEST];
    // eslint-disable-next-line array-callback-return
    strengthTest.forEach((el) => {
      if (Object.keys(graphChangehData[STRENGTH_TEST]).includes(el)) {
        let data = graphChangehData[STRENGTH_TEST][el];
        data = {
          [el]: {
            ...graphChangehData[STRENGTH_TEST][el],
            ...body.results.graph_table[STRENGTH_TEST][el],
          },
        };
        tempStrength = {
          ...tempStrength,
          ...data,
        };
      } else {
        tempStrength = {
          ...graphChangehData[STRENGTH_TEST],
          ...tempStrength,
        };
      }
    });
    let tempStability;
    tempStability = graphChangehData[STABILITY_TEST];
    // eslint-disable-next-line array-callback-return
    stabilityTest.forEach((el) => {
      if (Object.keys(graphChangehData[STABILITY_TEST]).includes(el)) {
        let data = graphChangehData[STABILITY_TEST][el];
        data = {
          [el]: {
            ...graphChangehData[STABILITY_TEST][el],
            ...body.results.graph_table[STABILITY_TEST][el],
          },
        };
        tempStability = {
          ...tempStability,
          ...data,
        };
      } else {
        tempStability = {
          ...graphChangehData[STABILITY_TEST],
          ...tempStability,
        };
      }
    });
    const graphData = {
      'strength test': tempStrength,
      'stability test': tempStability,
      'summary stats': { 'Summary Stats': summaryStats },
      'Feature Importance': { 'Feature Importance': featureImp },
      'summary stats logistic': summaryStatsLogistics,
    };
    return { tableData, graphData };
  },
  modelRoute: (SIDEBAR_MENUS) => {
    const modelMonitoringRoutes = [];
    // eslint-disable-next-line no-shadow
    function addRoute(data, modelMonitoringRoutes) {
      data.forEach((element) => {
        if (element.route) {
          modelMonitoringRoutes.push(element.route);
        }
        if (element.children) {
          addRoute(element.children, modelMonitoringRoutes);
        }
      });
      return modelMonitoringRoutes;
    }
    return addRoute(SIDEBAR_MENUS, modelMonitoringRoutes);
  },
  getBrowserName: () => {
    // gets the type of browser
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      return 'opera';
    }
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'chrome';
    }
    if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'safari';
    }
    if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'firefox';
    }
    if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      !!document.documentMode === true
    ) {
      return 'ie'; // crap
    }
    return 'unknown';
  },
  getPageUrlByModuleSubModuleName: (module, subModule) => {
    switch (module) {
      case MODULE_SUB_MODULE_OPERATIONS_TREE.DM.subModules.GR.key:
        return '/data-management/preparation';
      case MODULE_SUB_MODULE_OPERATIONS_TREE.ME.key:
        return '/model-estimation';
      case MODULE_SUB_MODULE_OPERATIONS_TREE.FE.key:
        return '/feature-engineering/feature-transformation';
      case MODULE_SUB_MODULE_OPERATIONS_TREE.VR.key:
        return '/variable-reduction';
      // case MODULE_SUB_MODULE_OPERATIONS_TREE.MD.key:
      //   return '/model-deployment';
      case MODULE_SUB_MODULE_OPERATIONS_TREE.SS.key:
        switch (subModule) {
          case MODULE_SUB_MODULE_OPERATIONS_TREE.SS.subModules.SA.key:
            return '/sampling-segmentation/sampling';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.SS.subModules.SE.key:
            return '/sampling-segmentation/segmentation';
          default:
            return '/sampling-segmentation';
        }
      case MODULE_SUB_MODULE_OPERATIONS_TREE.DM.key:
        switch (subModule) {
          case MODULE_SUB_MODULE_OPERATIONS_TREE.DM.subModules.DI.key:
            return '/data-management/data-ingestion';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.DM.subModules.MU.key:
            return '/data-management/model-upload';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.DM.subModules.DPA.key:
            return '/data-management/preparation';
          default:
            return '/data-management';
        }
      case MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.key:
        switch (subModule) {
          case MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.VA.key:
            return '/eda/variable-analysis';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.PS.key:
            return '/eda/preliminary-steps';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.TO.key:
            return '/eda/treating-outliers';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.MVI.key:
            return '/eda/missing-values-imputation';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.ST.key:
            return '/eda/statistical-tests';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.TSA.key:
            return '/eda/time-series-analysis';
          default:
            return '/eda';
        }
      case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.key:
        switch (subModule) {
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.PT.key:
            return '/model-testing/performance-testing';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.AT.key:
            return '/model-testing/assumption-testing';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.MC.key:
            return '/model-testing/model-comparison';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.SS.key:
            return '/model-testing/scoring-scaling';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.BT.key:
            return '/model-testing/back-testing';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.SA.key:
            return '/model-testing/sensitivity-analysis';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.FA.key:
            return '/model-testing/fairness';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.DA.key:
            return '/model-testing/drift-analysis';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.MR.key:
            return '/model-testing/calibiration';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.FC.key:
            return '/model-testing/forecasting';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.EM.key:
            return '/model-testing/escalation-matrix';
          default:
            return '/model-testing';
        }
      case MODULE_SUB_MODULE_OPERATIONS_TREE.MI.key:
        switch (subModule) {
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MI.subModules.FI.key:
            return '/model-interpretation/feature-interpretation';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MI.subModules.IP.key:
            return '/model-interpretation/individual-prediction';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MI.subModules.DT.key:
            return '/model-interpretation/decision-trees';
          case MODULE_SUB_MODULE_OPERATIONS_TREE.MI.subModules.TSA.key:
            return '/model-interpretation/time-series-analysis';
          default:
            return '/model-interpretation';
        }
      default:
        return '/';
    }
  },

  clearUrlParamsOfPushNotification: (queryParams) => {
    window.history.replaceState(null, null, window.location.pathname);
    if (queryParams) {
      queryParams.delete('jobId');
      queryParams.delete('operation');
      queryParams.delete('MSG_TYPE');
      queryParams.delete('action');
      queryParams.delete('module');
      queryParams.delete('subModule');
      queryParams.delete('projectId');
      queryParams.delete('userId');
      queryParams.delete('isConsole');
    }
  },
  getBase64Image: (img) => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  },
  isNewDatasetCreatedByUdf: (jobOutput) => {
    let isNewDatasetCreatedByUdf = false;
    if (jobOutput?.results?.isText) {
      jobOutput.results.texts.forEach((text) => {
        if (text.string.includes('Created')) {
          isNewDatasetCreatedByUdf = true;
        }
      });
    }
    return isNewDatasetCreatedByUdf;
  },

  getModuleBaseUrl(module, subModule) {
    const moduleBaseUrl = {
      [MODULE_SUB_MODULE_OPERATIONS_TREE.DM
        .key]: `${MODULE_BASE_URL.DM}/${API_MODULE_PATH_CONST.DM}`,
      [MODULE_SUB_MODULE_OPERATIONS_TREE.ME
        .key]: `${MODULE_BASE_URL.ME}/${API_MODULE_PATH_CONST.ME}`,
      [MODULE_SUB_MODULE_OPERATIONS_TREE.FE
        .key]: `${MODULE_BASE_URL.FTS}/${API_MODULE_PATH_CONST.FTS}`,
      [MODULE_SUB_MODULE_OPERATIONS_TREE.VR
        .key]: `${MODULE_BASE_URL.VR}/${API_MODULE_PATH_CONST.VR}`,
      [MODULE_SUB_MODULE_OPERATIONS_TREE.EDA
        .key]: `${MODULE_BASE_URL.EDA}/${API_MODULE_PATH_CONST.EDA}`,
      [MODULE_SUB_MODULE_OPERATIONS_TREE.SS.key]: {
        [MODULE_SUB_MODULE_OPERATIONS_TREE.SS.subModules.SA
          .key]: `${MODULE_BASE_URL.SAMPLING}/${API_MODULE_PATH_CONST.SAMPLING}`,
        [MODULE_SUB_MODULE_OPERATIONS_TREE.SS.subModules.SE
          .key]: `${MODULE_BASE_URL.SEG}/${API_MODULE_PATH_CONST.SEG}`,
      },
      [MODULE_SUB_MODULE_OPERATIONS_TREE.MT
        .key]: `${MODULE_BASE_URL.MT}/${API_MODULE_PATH_CONST.MT}`,
      [MODULE_SUB_MODULE_OPERATIONS_TREE.MI
        .key]: `${MODULE_BASE_URL.MI}/${API_MODULE_PATH_CONST.MI}`,
      // [MODULE_SUB_MODULE_OPERATIONS_TREE.MD
      //   .key]: `${MODULE_BASE_URL.MD}/${API_MODULE_PATH_CONST.MD}`,
    };

    if (module in moduleBaseUrl) {
      if (subModule && moduleBaseUrl[module][subModule]) {
        return moduleBaseUrl[module][subModule];
      }
      return moduleBaseUrl[module];
    }

    return null;
  },

  getModuleTaskListUrl: (module, subModule) => {
    switch (module) {
      case MODULE_SUB_MODULE_OPERATIONS_TREE.DM.key:
        return (
          ApiEndpoints.MODULE_BASE_URL.DM +
          ApiEndpoints.DM.FETCH_MODULE_TASKS_LIST
        );
      case MODULE_SUB_MODULE_OPERATIONS_TREE.ME.key:
        return (
          ApiEndpoints.MODULE_BASE_URL.ME +
          ApiEndpoints.ME.FETCH_MODULE_TASKS_LIST
        );
      case MODULE_SUB_MODULE_OPERATIONS_TREE.FE.key:
        return (
          ApiEndpoints.MODULE_BASE_URL.FTS +
          ApiEndpoints.FTS.FETCH_MODULE_TASKS_LIST
        );
      case MODULE_SUB_MODULE_OPERATIONS_TREE.VR.key:
        return (
          ApiEndpoints.MODULE_BASE_URL.VR +
          ApiEndpoints.VR.FETCH_MODULE_TASKS_LIST
        );
      case MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.key:
        return (
          ApiEndpoints.MODULE_BASE_URL.EDA +
          ApiEndpoints.EDA.FETCH_MODULE_TASKS_LIST
        );
      case MODULE_SUB_MODULE_OPERATIONS_TREE.SS.key:
        switch (subModule) {
          case MODULE_SUB_MODULE_OPERATIONS_TREE.SS.subModules.SA.key:
            return (
              ApiEndpoints.MODULE_BASE_URL.SAMPLING +
              ApiEndpoints.SAMPLING.FETCH_MODULE_TASKS_LIST
            );
          case MODULE_SUB_MODULE_OPERATIONS_TREE.SS.subModules.SE.key:
            return (
              ApiEndpoints.MODULE_BASE_URL.SEG +
              ApiEndpoints.SEG.FETCH_MODULE_TASKS_LIST
            );
          default:
            return null;
        }
      case MODULE_SUB_MODULE_OPERATIONS_TREE.MT.key:
        return (
          ApiEndpoints.MODULE_BASE_URL.MT +
          ApiEndpoints.MT.FETCH_MODULE_TASKS_LIST
        );
      case MODULE_SUB_MODULE_OPERATIONS_TREE.MI.key:
        return (
          ApiEndpoints.MODULE_BASE_URL.MI +
          ApiEndpoints.MI.FETCH_MODULE_TASKS_LIST
        );
      // case MODULE_SUB_MODULE_OPERATIONS_TREE.MD.key:
      //   return (
      //     ApiEndpoints.MODULE_BASE_URL.MD +
      //     ApiEndpoints.MD.FETCH_MODULE_TASKS_LIST
      //   );
      default:
        return null;
    }
  },
  toTitleCase: (str) => {
    if (str.includes(' ')) {
      return str.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase());
    }
    return str;
  },
  stripTags: (html) => {
    const parsed = new DOMParser().parseFromString(html, 'text/html');
    return parsed.body.textContent;
  },
  getUserDetailsFromStorage: () => {
    try {
      const activeProject = localStorage.ACTIVE_PROJECT;
      const userId = JSON.parse(localStorage.user_data).id;
      const userEmail = JSON.parse(localStorage.user_data).email;
      const userAndIdDetails =
        new Map(JSON.parse(localStorage.getItem('USER_ACTIVE_PROJECT_LIST'))) ||
        new Map();
      return {
        userAndIdDetails,
        activeProject,
        userId,
        userEmail,
      };
    } catch (e) {
      return {};
    }
  },
  checkPermissions: (userPermissions, allowedPermissions = []) => {
    if (allowedPermissions.length === 0) {
      return true;
    }
    return userPermissions.some((permission) =>
      allowedPermissions.includes(permission)
    );
  },
  delay: (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  // eslint-disable-next-line no-unused-vars
  prepareArgsConsole: (args, _fromPipeline = false) => {
    const preparedArgs = cloneDeep(args);
    preparedArgs.forEach((arg, index) => {
      switch (arg.type) {
        case 'Int':
        case 'Float':
          preparedArgs[index].value = Number(arg.value);
          break;
        case 'List':
        case 'String':
        case 'Boolean':
          preparedArgs[index].value = arg.value;
          break;
        case 'Model':
        case 'Dataset':
          preparedArgs[index].value = arg.value?.datasetId
            ? arg.value?.datasetId
            : arg.value?.id;
          break;
        case 'Column':
          preparedArgs[index].value = arg.value.map((item) => item.column_name);
          preparedArgs[index].dataset = arg.dataset.id;
          break;
        case 'ConsoleFiles':
          preparedArgs[index].value = arg.value?.id ? arg.value?.id : arg.value;
          break;
        default:
      }
    });
    return preparedArgs;
  },

  checkOverlappingNodes: (allNodes, x, y, buffer) => {
    const overlap = (nodes, x1, y1, buffer) => {
      const checkIsBetween = (preNodePos, curNodePos, buffer1) => {
        if (
          preNodePos > curNodePos - buffer1 &&
          preNodePos < curNodePos + buffer1
        ) {
          return true;
        }
      };

      const isOverlap = nodes.filter((node) => {
        const isCheckX = checkIsBetween(node?.position?.x, x1, buffer);
        const isCheckY = checkIsBetween(node?.position?.y, y1, buffer);
        return isCheckX && isCheckY;
      });
      if (isOverlap.length > 0) {
        return true;
      }
      return false;
    };

    const overlapChecks = (nodes1, x, y, buffer) => {
      let X1 = x;
      let Y1 = y;
      let resp = false;
      if (nodes1) {
        resp = overlap(nodes1, x, y, buffer);
      }

      if (resp === true) {
        X1 += buffer;
        Y1 += buffer;
        return overlapChecks(nodes1, X1, Y1, buffer);
      }
      return { x: X1, y: Y1 };
    };

    return overlapChecks(allNodes, x, y, buffer);
  },
  getTaskStatusColor: (taskStatus, theme) => {
    let responseColor;

    switch (taskStatus) {
      case 'SUCCESS':
      case 'done':
        responseColor = theme?.palette?.success?.main;
        break;
      case 'PENDING':
      case 'in-progress':
        responseColor = theme?.palette?.warning?.main;
        break;
      case 'FAILED':
      case 'failed':
        responseColor = theme?.palette?.error?.main;
        break;
      default:
        responseColor = theme?.palette?.text?.disabled;
    }

    return responseColor;
  },
  // eslint-disable-next-line no-shadow
  prepareNodeData: (values, ApiEndpoints, pipelineId) => {
    // const operation =
    //   MODULE_SUB_MODULE_OPERATIONS_TREE?.[values.module.objKey]?.subModules?.[
    //     values.submodule.objKey
    //   ]?.operations?.[values.operation.objKey];
    // const getRunOpUrlByActiveProject = (_operation, _activeProject) => {
    //   if (
    //     tsRegulationKeys?.includes(_activeProject?.regulation) &&
    //     _operation?.runOpUrlAlt
    //   ) {
    //     return _operation?.runOpUrlAlt;
    //   }
    //   if (values?.jobDetails?.parameters?.action === 'save') {
    //     return _operation?.runOpUrlAlt;
    //   }
    //   if (values.module.objKey === 'EDA' && values.operation.objKey === 'OD') {
    //     if (
    //       values?.jobDetails?.parameters?.internal_name === 'outlierdetection'
    //     ) {
    //       return _operation?.runOpUrl;
    //     }
    //     if (
    //       values?.jobDetails?.parameters?.internal_name ===
    //       'outlier_treatment_run'
    //     ) {
    //       return _operation?.runOpUrlTreatment;
    //     }
    //     return _operation?.runOpUrlAlt;
    //   }
    //   return _operation?.runOpUrl;
    // };
    const payloadData = {
      pipeline_id: pipelineId,
      node_name: values.nodeName,
      node_display_name: values.nodeName,
      module: values.module.key ? values.module.key : values.module,
      submodule: values.submodule.key ? values.submodule.key : values.submodule,
      type: values?.isUdf ? 'consoleJobNode' : 'normal',
      url: values?.isUdf
        ? ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.EXECUTE_UDF
        : values?.jobDetails?.url, // getRunOpUrlByActiveProject(operation, activeProject), //
      payload: {
        ...values?.jobDetails?.parameters,
        pipeline_id: pipelineId,
      },
      check_url: values?.jobDetails?.checkurl
        ? values?.jobDetails?.checkurl
        : ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.UDF_TASK_DETAILS,
      data: {
        udfDetails: values.udfDetails,
        operationDetails: !values?.isUdf && {
          moduleObjectKey: values.module.objKey
            ? values.module.objKey
            : values.module,
          submoduleObjectKey: values.submodule.objKey
            ? values.submodule.objKey
            : values.submodule,
          operation: values.operation,
        },
      },
      nodeDisplay: {},
      documentation_detail: [],
      node_input: values?.jobDetails?.artifacts?.inputs
        ? values?.jobDetails?.artifacts?.inputs
        : {},
      node_output: values?.jobDetails?.artifacts?.outputs
        ? values?.jobDetails?.artifacts?.outputs
        : {},
      moduleObjectKey: values.module.objKey
        ? values.module.objKey
        : values.module,
      submoduleObjectKey: values.submodule.objKey
        ? values.submodule.objKey
        : values.submodule,
      operation: values.operation,
      operationKey: values?.operation?.objKey,
      taskDetails: values?.jobDetails?.parameters,
    };
    return payloadData;
  },
  prepareDocOutputMappingObj: (commentData, nodeData) => {
    const type = commentData.type ? commentData.type : commentData.outputType;
    let moduleNameforMapping =
      nodeData?.data?.operationDetails?.moduleObjectKey;
    if (nodeData?.data?.operationDetails?.moduleObjectKey === 'FE') {
      moduleNameforMapping = 'FTS';
    }
    if (nodeData?.data?.operationDetails?.moduleObjectKey === 'SS') {
      if (nodeData?.data?.operationDetails?.submoduleObjectKey === 'SA') {
        moduleNameforMapping = 'SAMPLING';
      }
      if (nodeData?.data?.operationDetails?.submoduleObjectKey === 'SE') {
        moduleNameforMapping = 'SEG';
      }
    }
    const consoleTableUrl = `${ApiEndpoints.MODULE_BASE_URL.CONSOLE}/functions${ApiEndpoints.SAVE_TABLE_COMMENT}`;
    const consoleChartUrl = `${ApiEndpoints.MODULE_BASE_URL.CONSOLE}/functions${ApiEndpoints.SAVE_CHART_COMMENT}`;
    const normalOpsTableUrl = `${ApiEndpoints.MODULE_BASE_URL[moduleNameforMapping]}/${API_MODULE_PATH_CONST?.[moduleNameforMapping]}${ApiEndpoints.SAVE_TABLE_COMMENT}`;
    const normalOpsChartUrl = `${ApiEndpoints.MODULE_BASE_URL[moduleNameforMapping]}/${API_MODULE_PATH_CONST?.[moduleNameforMapping]}${ApiEndpoints.SAVE_CHART_COMMENT}`;
    const obj = {
      type,
      url:
        // eslint-disable-next-line no-nested-ternary
        type === 'table'
          ? nodeData.type === 'consoleJobNode'
            ? consoleTableUrl
            : normalOpsTableUrl
          : nodeData.type === 'consoleJobNode'
          ? consoleChartUrl
          : normalOpsChartUrl,
      input: `${nodeData?.node_name}_${commentData?.index}`,
      payload: {
        table_name: commentData.label,
        id: `{{ti.xcom_pull(task_ids='check_${nodeData?.node_name}',key='${nodeData?.node_name}_${commentData?.index}')}}`,
        comment: commentData.comment,
        replace: true,
        append: false,
        document_id: `{{dag_run.conf['doc_id']}}`,
        node_id: commentData?.table_id?.id,
        table_id: commentData?.table_id,
        table_heading: commentData?.table_heading,
        ...(commentData?.img_heading && {
          img_heading: commentData?.img_heading,
        }),
        actualId: commentData.key,
        section_id: '',
      },
    };
    return obj;
  },
  // Refer : https://algorithms.tutorialhorizon.com/graph-detect-cycle-in-a-directed-graph-using-colors/
  // closure function
  graphCycleDetection: (_graph) => {
    const visited = new Map();
    const backEdges = new Map();
    const adjList = {};
    function isCyclicRecursiveDFS(currentNode) {
      visited.set(currentNode, true);
      backEdges.set(currentNode, true);
      const neighbours = adjList[currentNode];

      // DFS traversal
      if (neighbours) {
        for (const node of neighbours) {
          if (!visited.has(node)) {
            if (isCyclicRecursiveDFS(node)) {
              return true;
            }
          } else if (backEdges.has(node)) {
            return true;
          }
        }
      }
      backEdges.delete(currentNode);
      return false;
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < _graph.length; i++) {
      const nodeName = _graph[i].source;
      const targetNode = _graph[i].target;
      if (nodeName in adjList) adjList[nodeName].push(targetNode);
      else adjList[nodeName] = [targetNode];
    }
    for (const node in adjList) {
      if (!visited.has(node)) {
        if (isCyclicRecursiveDFS(node)) {
          return true;
        }
      }
    }
    return false;
    /*
     const colors = {};
    let cycleDetected = false;
    function runVerification(node) {
      if (colors[node] === 'black') {
        // black = ok
        return;
      }

      if (colors[node] === 'grey') {
        // grey = visited while its children are being visited
        // cycle!
        cycleDetected = true;
        // console.log('cycle', colors);
        return node;
      }

      // mark as being visited
      colors[node] = 'grey';

      // visit children
      _graph.forEach((edge) => {
        if (edge?.source === node) runVerification(edge?.target);
      });

      // mark as visited and ok
      colors[node] = 'black';
    }
    return {
      runVerification,
      getCycleDetected() {
        return cycleDetected;
      },
    };
    */
  },
  getCardCount: (windowWidth) => {
    const cardWidth = Math.floor(
      342 + (windowWidth % 342) / Math.floor(windowWidth / 342) - 16
    );
    const count = Math.floor(windowWidth / cardWidth);
    return count;
  },

  getLabelUsingKey: ({ name, isModule, isSubModule, isOperation }) => {
    if (name in window.MS_TREE_CACHE) {
      return window.MS_TREE_CACHE[name];
    }
    if (isModule) {
      let moduleLabel;
      Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE).forEach((ele) => {
        if (MODULE_SUB_MODULE_OPERATIONS_TREE[ele].key === name) {
          moduleLabel = MODULE_SUB_MODULE_OPERATIONS_TREE[ele].label;
        }
      });
      window.MS_TREE_CACHE[name] = moduleLabel;
      return moduleLabel;
    }
    if (isSubModule) {
      let subModuleLabel;
      Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE).forEach((ele) => {
        Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE[ele].subModules).forEach(
          (sub) => {
            if (
              MODULE_SUB_MODULE_OPERATIONS_TREE[ele].subModules[sub].key ===
              name
            ) {
              subModuleLabel =
                MODULE_SUB_MODULE_OPERATIONS_TREE[ele].subModules[sub].label;
            }
          }
        );
      });
      window.MS_TREE_CACHE[name] = subModuleLabel;
      return subModuleLabel;
    }
    if (isOperation) {
      let operationLabel;
      Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE).forEach((ele) => {
        Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE[ele].subModules).forEach(
          (sub) => {
            if (
              MODULE_SUB_MODULE_OPERATIONS_TREE[ele]?.subModules[sub]
                ?.operations
            ) {
              Object.keys(
                MODULE_SUB_MODULE_OPERATIONS_TREE[ele]?.subModules[sub]
                  ?.operations
              ).forEach((op) => {
                if (
                  MODULE_SUB_MODULE_OPERATIONS_TREE[ele]?.subModules[sub]
                    ?.operations[op].key === name
                ) {
                  operationLabel =
                    MODULE_SUB_MODULE_OPERATIONS_TREE[ele]?.subModules[sub]
                      ?.operations[op].label;
                }
              });
            }
          }
        );
      });
      window.MS_TREE_CACHE[name] = operationLabel;
      return operationLabel;
    }
    return '';
  },

  getObjectUsingKey: ({ name, isModule, isSubModule, isOperation }) => {
    if (isOperation) {
      let operationObj;
      Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE).forEach((ele) => {
        Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE[ele].subModules).forEach(
          (sub) => {
            if (
              MODULE_SUB_MODULE_OPERATIONS_TREE[ele]?.subModules[sub]
                ?.operations
            ) {
              Object.keys(
                MODULE_SUB_MODULE_OPERATIONS_TREE[ele]?.subModules[sub]
                  ?.operations
              ).forEach((op) => {
                if (
                  MODULE_SUB_MODULE_OPERATIONS_TREE[ele]?.subModules[sub]
                    ?.operations[op].key === name
                ) {
                  operationObj =
                    MODULE_SUB_MODULE_OPERATIONS_TREE[ele]?.subModules[sub]
                      ?.operations[op];
                }
              });
            }
          }
        );
      });
      window.MS_TREE_CACHE[name] = operationObj;
      return operationObj;
    }
    if (name in window.MS_TREE_CACHE) {
      return window.MS_TREE_CACHE[name];
    }
    if (isModule) {
      let moduleObj;
      Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE).forEach((ele) => {
        if (MODULE_SUB_MODULE_OPERATIONS_TREE[ele].key === name) {
          moduleObj = MODULE_SUB_MODULE_OPERATIONS_TREE[ele];
        }
      });
      window.MS_TREE_CACHE[name] = moduleObj;
      return moduleObj;
    }
    if (isSubModule) {
      let subModuleObj;
      Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE).forEach((ele) => {
        Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE[ele].subModules).forEach(
          (sub) => {
            if (
              MODULE_SUB_MODULE_OPERATIONS_TREE[ele].subModules[sub].key ===
              name
            ) {
              subModuleObj =
                MODULE_SUB_MODULE_OPERATIONS_TREE[ele].subModules[sub];
            }
          }
        );
      });
      window.MS_TREE_CACHE[name] = subModuleObj;
      return subModuleObj;
    }

    return '';
  },

  getModuleSubmoduleOptionsAsPerPermission: (
    type,
    permission,
    moduleObjKey,
    submoduleObjKey,
    regulation
  ) => {
    let result = false;
    if (type === 'MODULE') {
      result = ['Manage', 'Custom'].includes(
        permission[`${MODULE_SUB_MODULE_OPERATIONS_TREE[moduleObjKey].key}`]
      );
    } else {
      const moduleObject = MODULE_SUB_MODULE_OPERATIONS_TREE[moduleObjKey];
      result =
        ['View', 'Manage', 'Custom']?.includes(
          permission[
            `${moduleObject?.key}_${moduleObject?.subModules[submoduleObjKey]?.key}` ??
              ''
          ] ?? ''
        ) &&
        MODULE_SUB_MODULE_OPERATIONS_TREE[moduleObjKey]?.subModules[
          submoduleObjKey
        ]?.regulation.includes(regulation);
    }
    return result;
  },

  getModuleSubmoduleOptionsAsPerNonePermission: (
    type,
    permission,
    regulation,
    moduleObjKey,
    submoduleObjKey = ''
  ) => {
    let result = true;
    if (type === 'MODULE') {
      result =
        !['None'].includes(
          permission[`${MODULE_SUB_MODULE_OPERATIONS_TREE[moduleObjKey]?.key}`]
        ) &&
        MODULE_SUB_MODULE_OPERATIONS_TREE[moduleObjKey]?.regulation?.includes(
          regulation
        );
    } else {
      const moduleObject = MODULE_SUB_MODULE_OPERATIONS_TREE[moduleObjKey];
      result =
        !['None']?.includes(
          permission[
            `${moduleObject?.key}_${moduleObject?.subModules[submoduleObjKey]?.key}` ??
              ''
          ] ?? ''
        ) &&
        MODULE_SUB_MODULE_OPERATIONS_TREE[moduleObjKey]?.subModules[
          submoduleObjKey
        ]?.regulation.includes(regulation);
    }
    return result;
  },

  base64SvgToOtherBase64: (svgString, format, callback) => {
    let width = 1000;
    let height = 1000;
    // trying to auto calculate height and width
    try {
      let stripFromSvgString = svgString;
      if (svgString?.startsWith('data:image/svg+xml;base64')) {
        stripFromSvgString = svgString?.replace(
          'data:image/svg+xml;base64,',
          ''
        );
      }
      const decodedSvg = atob(stripFromSvgString);
      const parser = new DOMParser();
      const doc = parser.parseFromString(decodedSvg, 'image/svg+xml');
      height = Number(doc.childNodes[0].getAttribute('height'));
      width = Number(doc.childNodes[0].getAttribute('width'));
    } catch (e) {
      height = 1000;
      width = 1000;
      console.log(e);
    }

    // set default for format parameter
    // eslint-disable-next-line no-param-reassign
    format = format || 'png';
    // SVG data URL from SVG string
    const svgData = svgString;
    // create canvas in memory(not in DOM)
    const canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    const context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    const image = new Image();
    // later when image loads run this
    // eslint-disable-next-line func-names
    image.onload = function () {
      // async (happens later)
      // clear canvas
      context.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      const pngData = canvas.toDataURL(`image/${format}`);
      // pass png data URL to callback
      callback(pngData);
    }; // end async
    // start loading SVG data into in memory image
    image.src = svgData;
  },

  resetConsoleArgs: (value) => {
    if (value?.args?.length) {
      value.args.forEach((arg, index) => {
        // eslint-disable-next-line no-param-reassign
        value.args[index] = { ...arg, value: undefined };
        if (arg.type === 'Column') {
          // eslint-disable-next-line no-param-reassign
          value.args[index] = { ...arg, value: undefined, dataset: undefined };
        }
      });
    }
  },
  /**
   * This function exports HTML content as a Microsoft Word document.
   * @param element - The ID of the HTML element that you want to export to a Word document.
   * @param [filename] - The name of the file that will be downloaded. If no name is provided, the
   * default name will be "document.doc".
   */
  exportToWord(element, filename = '', removeElement) {
    const body = document.getElementById(element);
    const clone = body.cloneNode(true);
    const removeChildElements = clone.querySelectorAll(removeElement);
    removeChildElements.forEach((el) => el.remove());
    // Remove Material-UI Table Pagination component
    const paginationContainer = clone.querySelectorAll(
      '.MuiTablePagination-root'
    );
    paginationContainer.forEach((table) => {
      table.remove();
    });
    // removing label
    const inputLabel = clone.querySelectorAll('.MuiInputLabel-root');
    inputLabel.forEach((label) => {
      label.remove();
    });
    const hiddenFieldset = clone.querySelectorAll(
      'fieldset[aria-hidden="true"]'
    );
    hiddenFieldset.forEach((ele) => {
      ele.remove();
    });
    // Handling table headers
    const tableHeadings = clone.querySelectorAll('th');
    tableHeadings.forEach((heading) => {
      // eslint-disable-next-line no-param-reassign
      heading.innerText = heading.innerText.toUpperCase();
    });
    // Handle input values
    const inputElements = clone.querySelectorAll('input, textarea');
    inputElements.forEach((input) => {
      const { value } = input;
      const placeholder = document.createElement('div');
      placeholder.innerHTML = value;
      input.parentNode.replaceChild(placeholder, input);
    });
    // handling underline elements
    const insElements = clone.querySelectorAll('ins');
    insElements.forEach((ins) => {
      // eslint-disable-next-line no-param-reassign
      ins.style.textDecoration = 'underline';
    });
    // adding colour to table cells
    const tableColoredCell = clone.querySelectorAll('td');
    tableColoredCell.forEach((ele) => {
      if (ele.innerText === 'RED') {
        // eslint-disable-next-line no-param-reassign
        ele.style.backgroundColor = 'rgb(255, 0, 0)';
      } else if (ele.innerText === 'AMBER') {
        // eslint-disable-next-line no-param-reassign
        ele.style.backgroundColor = 'rgb(255, 191, 0)';
      } else if (ele.innerText === 'GREEN') {
        // eslint-disable-next-line no-param-reassign
        ele.style.backgroundColor = 'rgb(63, 204, 45)';
      }
    });
    const css =
      'table{border-collapse:collapse} th{border:1px gray solid;width:7em;padding:2px;} td{border:1px gray solid;width:7em;padding:2px;}';
    const header = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word" xmlns="http://www.w3.org/TR/REC-html40"><head><meta charset="utf-8"><title>Export HTML to Word Document</title><style>
      ${css}
      </style></head><body>`;
    const footer = '</body></html>';

    const images = clone.querySelectorAll('img');
    const promises = [];

    images.forEach((img) => {
      const src = img.getAttribute('src');
      const loadedImage = new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.addEventListener('load', () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0);

          canvas.toBlob(
            (blob) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64data = reader.result;
                // eslint-disable-next-line no-param-reassign
                img.src = base64data;
                resolve();
              };
              reader.readAsDataURL(blob);
            },
            'image/png',
            1
          );
        });
        image.addEventListener('error', () => {
          reject();
        });
        image.src = src;
      });
      promises.push(loadedImage);
    });

    Promise.all(promises)
      .then(() => {
        const html = header + clone.innerHTML + footer;
        const blob = new Blob(['\ufeff', html], {
          type: 'application/msword',
        });
        // eslint-disable-next-line no-param-reassign
        filename = filename ? `${filename}.doc` : 'document.doc';
        const tempAnchor = document.createElement('a');
        tempAnchor.href = URL.createObjectURL(blob);
        tempAnchor.download = filename;
        tempAnchor.click();
        URL.revokeObjectURL(tempAnchor.href);
      })
      .catch((error) => {
        throw error;
      });
  },
  /**
   * This function converts a specified HTML element into a PDF file and downloads it.
   * @param name - The name of the PDF file that will be downloaded.
   * @param div - The div parameter is the ID of the HTML element that contains the content to be
   * converted to PDF and downloaded.
   */
  pdfDownloadHandler(name, div) {
    const input = document.getElementById(div);
    html2canvas(input, {
      useCORS: true,
    }).then((canvas) => {
      // Create a new PDF canvas.
      const imgData = canvas.toDataURL('image/jpeg');
      const pdf = new JsPDF('p', 'pt', [canvas.width, canvas.height]);
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      // convert your PDF and save to file
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save(`${name}.pdf`); // Download the rendered PDF.
    });
  },
  getColumnObjectsByColName: (columns, columnName) => {
    if (Array.isArray(columnName)) {
      return columns?.filter((item) => {
        return columnName?.includes(item.column_name);
      });
    }
    return columns?.filter((item) => {
      return columnName === item.column_name;
    });
  },

  getDatasetObjectBydatasetName: (datasets, datasetName) => {
    if (Array.isArray(datasetName)) {
      return datasets?.filter((item) => {
        return datasetName?.includes(item.name);
      });
    }
    return datasets?.filter((item) => {
      return datasetName === item.name;
    });
  },
  formatBytes: (size) => {
    if (size === 0) {
      return '0 KB';
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let sizeValue = size;
    let i = 0;
    while (sizeValue >= 1024 && i < sizes.length) {
      sizeValue /= 1024;
      i += 1;
    }
    return `${sizeValue?.toFixed(2)} ${sizes[i]}`;
  },
  extractModelTechniqueObj(modelType, techniqueValues) {
    switch (modelType) {
      case 'GEE/GLM':
        return GlmGeeModelTechinquesMI.filter((item) =>
          techniqueValues.includes(item.value)
        );
      case 'Time series':
        return TimeSeriesModelTechinquesMI.filter((item) =>
          techniqueValues.includes(item.value)
        );
      case 'VECM':
        return VECMModelTechinquesMI.filter((item) =>
          techniqueValues.includes(item.value)
        );
      default:
        return [];
    }
  },
  downloadImageFile: (imageBase64) => {
    const link = document.createElement('a');
    link.href = `data:image/png;base64,${imageBase64}`;
    link.download = 'image.png';
    link.click();
  },
  getPlaceholderTextByArgType: (type = '') => {
    switch (type) {
      case 'String':
        return 'Enter text/string';
      case 'Int':
        return 'Enter number';
      case 'Float':
        return 'Enter decimal number';
      case 'Boolean':
        return 'Select option';
      case 'List':
        return 'Enter comma separated values';
      case 'Model':
        return 'Select model';
      case 'Dataset':
        return 'Select dataset';
      case 'Column':
        return 'Select dataset variables';
      case 'ConsoleFiles':
        return 'Select console file';
      default:
        return 'Enter value';
    }
  },
  getLabelTextByArgType: (type = '') => {
    switch (type) {
      case 'String':
        return 'Value*';
      case 'Int':
        return 'Value*';
      case 'Float':
        return 'Value*';
      case 'Boolean':
        return 'Option*';
      case 'List':
        return 'Enter comma separated values*';
      case 'Model':
        return 'Model*';
      case 'Dataset':
        return 'Dataset*';
      case 'Column':
        return 'Dataset variables*';
      case 'ConsoleFiles':
        return 'Console file*';
      default:
        return 'Value*';
    }
  },
  getInitials: (nameString) => {
    if (!nameString) return '';
    const sanitizedName = nameString.replace(/[^a-zA-Z ]/g, '');
    const words = sanitizedName.split(' ');
    const initials = words
      .map((word) => word.charAt(0))
      .join('')
      .toUpperCase();

    return initials;
  },
  getModuleNameFromURL: () => {
    const segments = window.location.href.split('/');
    return segments?.length >= 4
      ? segments?.slice(3, 5)?.map((segment) => segment.split('?')[0])
      : [];
  },
  checkForIds: (id1, id2) => {
    if (!id1 || !id2 || typeof id1 !== 'string' || typeof id2 !== 'string')
      return false;
    const regex = /task_ids='(.*?)',key='(.*?)'/;
    const [, taskId1, key1] = id1.match(regex);
    const [, taskId2, key2] = id2.match(regex);

    if (taskId1 && taskId2 && key1 && key2) {
      return taskId1 === taskId2 && key1 === key2;
    }

    return false;
  },

  /**
   * 📌 Note: This custom implementation is used instead of lodash's `isEmpty` function
   *       due to a known issue with lodash's handling of numbers, false, true values.
   *       Refer to https://github.com/lodash/lodash/issues/496 for more information.
   *
   * The function checks if a value is considered empty, handling various data types.
   * @param value - The value parameter is a variable that can hold any data type (undefined, null,
   * object, string, number, boolean, etc.) and is used to check if it is empty or not.
   * @returns The function `isValueEmpty` takes in a parameter `value` and returns a boolean value. It
   * returns `true` if the value is `undefined`, `null`, an empty object, or a string with only
   * whitespace characters. Otherwise, it returns `false`.
   */
  isValueEmpty: (value) => {
    return (
      value === undefined ||
      value === null ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0)
    );
  },
};

export default utils;

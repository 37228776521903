/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticate: !!localStorage.getItem('access_token'),
  userData: JSON.parse(localStorage.getItem('user_data')),
  profileData: null,
  permissions: JSON.parse(localStorage.getItem('user_permissions')) || {},
};

/**
 * @function counterSlice - Create redux slice
 * @description - Create redux slice for counter
 * @param {string} name - Slice name
 * @param {object} initialState - Initial state
 * @param {object} reducers - Reducers
 * @returns {object} - Redux slice
 */
export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      localStorage.setItem('access_token', action.payload.token);
      localStorage.setItem('refresh_token', action.payload.refresh);
      localStorage.setItem('user_data', JSON.stringify(action.payload.user));
      try {
        // cookieStore is new API may not work in all browser.
        // we are using it share storage between sw.js and Nimbus code. Why ? because sw.js not supporting localstorage.
        // @ts-ignore
        window?.cookieStore?.set('user', JSON.stringify(action.payload.user));
        // eslint-disable-next-line no-empty
      } catch (e) {}
      state.isAuthenticate = true;
      state.userData = action.payload.user;
      state.profileData = action.payload.profile;
    },
    setUserPermissions: (state, action) => {
      localStorage.setItem(
        'user_permissions',
        JSON.stringify(action.payload.permissions)
      );
      state.permissions = action.payload.permissions;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginSuccess, setUserPermissions } = counterSlice.actions;

export default counterSlice.reducer;
